* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Chillax', 'Montserrat', sans-serif;
}

:root {
    --toastify-color-success: #5056fd;
}

body {
    overflow-x: hidden;
    background: #f9f9f9;
}

body > iframe[style*='2147483647']{
    display: none;
}